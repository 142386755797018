@import "@progress/kendo-theme-core/scss/functions/index.import.scss";
@import "@progress/kendo-theme-core/scss/color-system/_constants.scss";
@import "./_palettes.scss";

// Config
$kendo-enable-color-system: false !default;

// Colors
$_default-colors: (
    // Misc
    app-surface: k-map-get( $kendo-palette-gray, white ),
    on-app-surface: k-map-get( $kendo-palette-gray, 12 ),
    subtle: k-map-get( $kendo-palette-gray, 10 ),
    surface: k-map-get( $kendo-palette-gray, 1 ),
    surface-alt: k-map-get( $kendo-palette-gray, white ),
    border: rgba( k-map-get( $kendo-palette-gray, black ), .08),
    border-alt: rgba( k-map-get( $kendo-palette-gray, black ), .16),
    // Base
    base-subtle: k-map-get( $kendo-palette-gray, 3 ),
    base-subtle-hover: k-map-get( $kendo-palette-gray, 4 ),
    base-subtle-active: k-map-get( $kendo-palette-gray, 5 ),
    base: k-map-get( $kendo-palette-gray, 2 ),
    base-hover: k-map-get( $kendo-palette-gray, 3 ),
    base-active: k-map-get( $kendo-palette-gray, 5 ),
    base-emphasis: k-map-get( $kendo-palette-gray, 6 ),
    base-on-subtle: k-map-get( $kendo-palette-gray, 12 ),
    on-base: k-map-get( $kendo-palette-gray, 12 ),
    base-on-surface: k-map-get( $kendo-palette-gray, 12 ),
    // Primary
    primary-subtle: k-map-get( $kendo-palette-coral, 2 ),
    primary-subtle-hover: k-map-get( $kendo-palette-coral, 3 ),
    primary-subtle-active: k-map-get( $kendo-palette-coral, 4 ),
    primary: k-map-get( $kendo-palette-coral, 9 ),
    primary-hover: k-map-get( $kendo-palette-coral, 10 ),
    primary-active: k-map-get( $kendo-palette-coral, 11 ),
    primary-emphasis: k-map-get( $kendo-palette-coral, 6 ),
    primary-on-subtle: k-map-get( $kendo-palette-coral, 15 ),
    on-primary: k-map-get( $kendo-palette-gray, white ),
    primary-on-surface: k-map-get( $kendo-palette-coral, 9 ),
    // Secondary
    secondary-subtle: k-map-get( $kendo-palette-gray, 1 ),
    secondary-subtle-hover: k-map-get( $kendo-palette-gray, 2 ),
    secondary-subtle-active: k-map-get( $kendo-palette-gray, 3 ),
    secondary: k-map-get( $kendo-palette-gray, 10 ),
    secondary-hover: k-map-get( $kendo-palette-gray, 11 ),
    secondary-active: k-map-get( $kendo-palette-gray, 12 ),
    secondary-emphasis: k-map-get( $kendo-palette-gray, 4 ),
    secondary-on-subtle: k-map-get( $kendo-palette-gray, 15 ),
    on-secondary: k-map-get( $kendo-palette-gray, white ),
    secondary-on-surface: k-map-get( $kendo-palette-gray, 13 ),
    // Tertiary
    tertiary-subtle: k-map-get( $kendo-palette-sky-blue, 2 ),
    tertiary-subtle-hover: k-map-get( $kendo-palette-sky-blue, 3 ),
    tertiary-subtle-active: k-map-get( $kendo-palette-sky-blue, 4 ),
    tertiary: k-map-get( $kendo-palette-sky-blue, 9 ),
    tertiary-hover: k-map-get( $kendo-palette-sky-blue, 10 ),
    tertiary-active: k-map-get( $kendo-palette-sky-blue, 11 ),
    tertiary-emphasis: k-map-get( $kendo-palette-sky-blue, 6 ),
    tertiary-on-subtle: k-map-get( $kendo-palette-sky-blue, 15 ),
    on-tertiary: k-map-get( $kendo-palette-gray, white ),
    tertiary-on-surface: k-map-get( $kendo-palette-sky-blue, 11 ),
    // Info
    info-subtle: k-map-get( $kendo-palette-blue, 2 ),
    info-subtle-hover: k-map-get( $kendo-palette-blue, 3 ),
    info-subtle-active: k-map-get( $kendo-palette-blue, 5 ),
    info: k-map-get( $kendo-palette-blue, 9 ),
    info-hover: k-map-get( $kendo-palette-blue, 10 ),
    info-active: k-map-get( $kendo-palette-blue, 11 ),
    info-emphasis: k-map-get( $kendo-palette-blue, 6 ),
    info-on-subtle: k-map-get( $kendo-palette-blue, 15 ),
    on-info: k-map-get( $kendo-palette-gray, white ),
    info-on-surface: k-map-get( $kendo-palette-blue, 11 ),
    // Success
    success-subtle: k-map-get( $kendo-palette-green, 2 ),
    success-subtle-hover: k-map-get( $kendo-palette-green, 3 ),
    success-subtle-active: k-map-get( $kendo-palette-green, 4 ),
    success: k-map-get( $kendo-palette-green, 9 ),
    success-hover: k-map-get( $kendo-palette-green, 10 ),
    success-active: k-map-get( $kendo-palette-green, 11 ),
    success-emphasis: k-map-get( $kendo-palette-green, 6 ),
    success-on-subtle: k-map-get( $kendo-palette-green, 15 ),
    on-success: k-map-get( $kendo-palette-gray, white ),
    success-on-surface: k-map-get( $kendo-palette-green, 11 ),
    // Warning
    warning-subtle: k-map-get( $kendo-palette-yellow, 2 ),
    warning-subtle-hover: k-map-get( $kendo-palette-yellow, 3 ),
    warning-subtle-active: k-map-get( $kendo-palette-yellow, 4 ),
    warning: k-map-get( $kendo-palette-yellow, 9 ),
    warning-hover: k-map-get( $kendo-palette-yellow, 10 ),
    warning-active: k-map-get( $kendo-palette-yellow, 11 ),
    warning-emphasis: k-map-get( $kendo-palette-yellow, 6 ),
    warning-on-subtle: k-map-get( $kendo-palette-yellow, 15 ),
    on-warning: k-map-get( $kendo-palette-gray, 12 ),
    warning-on-surface: k-map-get( $kendo-palette-yellow, 9 ),
    // Error
    error-subtle: k-map-get( $kendo-palette-red, 2 ),
    error-subtle-hover: k-map-get( $kendo-palette-red, 3 ),
    error-subtle-active: k-map-get( $kendo-palette-red, 5 ),
    error: k-map-get( $kendo-palette-red, 9 ),
    error-hover: k-map-get( $kendo-palette-red, 10 ),
    error-active: k-map-get( $kendo-palette-red, 11 ),
    error-emphasis: k-map-get( $kendo-palette-red, 6 ),
    error-on-subtle: k-map-get( $kendo-palette-red, 15 ),
    on-error: k-map-get( $kendo-palette-gray, white ),
    error-on-surface: k-map-get( $kendo-palette-red, 11 ),
    // Light
    light-subtle: k-map-get( $kendo-palette-gray, 1 ),
    light-subtle-hover: k-map-get( $kendo-palette-gray, 2 ),
    light-subtle-active: k-map-get( $kendo-palette-gray, 3 ),
    light: k-map-get( $kendo-palette-gray, 3 ),
    light-hover: k-map-get( $kendo-palette-gray, 4 ),
    light-active: k-map-get( $kendo-palette-gray, 5 ),
    light-emphasis: k-map-get( $kendo-palette-gray, 5 ),
    light-on-subtle: k-map-get( $kendo-palette-gray, 15 ),
    on-light: k-map-get( $kendo-palette-gray, black ),
    light-on-surface: k-map-get( $kendo-palette-gray, 4 ),
    // Dark
    dark-subtle: k-map-get( $kendo-palette-gray, 6 ),
    dark-subtle-hover: k-map-get( $kendo-palette-gray, 7 ),
    dark-subtle-active: k-map-get( $kendo-palette-gray, 8 ),
    dark: k-map-get( $kendo-palette-gray, 12 ),
    dark-hover: k-map-get( $kendo-palette-gray, 13 ),
    dark-active: k-map-get( $kendo-palette-gray, 14 ),
    dark-emphasis: k-map-get( $kendo-palette-gray, 10 ),
    dark-on-subtle: k-map-get( $kendo-palette-gray, 14 ),
    on-dark: k-map-get( $kendo-palette-gray, white ),
    dark-on-surface: k-map-get( $kendo-palette-gray, 15 ),
    // Inverse
    inverse-subtle: k-map-get( $kendo-palette-gray, 6 ),
    inverse-subtle-hover: k-map-get( $kendo-palette-gray, 7 ),
    inverse-subtle-active: k-map-get( $kendo-palette-gray, 8 ),
    inverse: k-map-get( $kendo-palette-gray, 12 ),
    inverse-hover: k-map-get( $kendo-palette-gray, 13 ),
    inverse-active: k-map-get( $kendo-palette-gray, 14 ),
    inverse-emphasis: k-map-get( $kendo-palette-gray, 10 ),
    inverse-on-subtle: k-map-get( $kendo-palette-gray, 14 ),
    on-inverse: k-map-get( $kendo-palette-gray, white ),
    inverse-on-surface: k-map-get( $kendo-palette-gray, 15 ),
    // Series A
    series-a: k-map-get( $kendo-palette-coral, 9 ),
    series-a-bold: k-map-get( $kendo-palette-coral, 12 ),
    series-a-bolder: k-map-get( $kendo-palette-coral, 14 ),
    series-a-subtle: k-map-get( $kendo-palette-coral, 5 ),
    series-a-subtler: k-map-get( $kendo-palette-coral, 7 ),
    // Series B
    series-b: k-map-get( $kendo-palette-lemon-yellow, 9 ),
    series-b-bold: k-map-get( $kendo-palette-lemon-yellow, 12 ),
    series-b-bolder: k-map-get( $kendo-palette-lemon-yellow, 14 ),
    series-b-subtle: k-map-get( $kendo-palette-lemon-yellow, 5 ),
    series-b-subtler: k-map-get( $kendo-palette-lemon-yellow, 7 ),
    // Series C
    series-c: k-map-get( $kendo-palette-spring-green, 9 ),
    series-c-bold: k-map-get( $kendo-palette-spring-green, 12 ),
    series-c-bolder: k-map-get( $kendo-palette-spring-green, 14 ),
    series-c-subtle: k-map-get( $kendo-palette-spring-green, 5 ),
    series-c-subtler: k-map-get( $kendo-palette-spring-green, 7 ),
    // Series D
    series-d: k-map-get( $kendo-palette-royal-blue, 9 ),
    series-d-bold: k-map-get( $kendo-palette-royal-blue, 12 ),
    series-d-bolder: k-map-get( $kendo-palette-royal-blue, 14 ),
    series-d-subtle: k-map-get( $kendo-palette-royal-blue, 5 ),
    series-d-subtler: k-map-get( $kendo-palette-royal-blue, 7 ),
    // Series Е
    series-e: k-map-get( $kendo-palette-lavender-purple, 9 ),
    series-e-bold: k-map-get( $kendo-palette-lavender-purple, 12 ),
    series-e-bolder: k-map-get( $kendo-palette-lavender-purple, 14 ),
    series-e-subtle: k-map-get( $kendo-palette-lavender-purple, 5 ),
    series-e-subtler: k-map-get( $kendo-palette-lavender-purple, 7 ),
    // Series F
    series-f: k-map-get( $kendo-palette-flamingo-pink, 9 ),
    series-f-bold: k-map-get( $kendo-palette-flamingo-pink, 12 ),
    series-f-bolder: k-map-get( $kendo-palette-flamingo-pink, 14 ),
    series-f-subtle: k-map-get( $kendo-palette-flamingo-pink, 5 ),
    series-f-subtler: k-map-get( $kendo-palette-flamingo-pink, 7 ),
) !default;

/// The global default Colors map.
/// @group color-system
$kendo-colors: $_default-colors !default;

$kendo-is-dark-theme: false !default;

// Theme colors
/// The color that focuses the user attention.
/// Used for primary buttons and for elements of primary importance across the theme.
/// @group color-system
/// @type Color
$kendo-color-primary: #ff6358 !default;
$kendo-color-primary-lighter: k-color-tint($kendo-color-primary, 2) !default;
$kendo-color-primary-darker: k-color-shade($kendo-color-primary, 2) !default;
/// The color used along with the primary color denoted by $kendo-color-primary.
/// Used to provide contrast between the background and foreground colors.
/// @group color-system
/// @type Color
$kendo-color-primary-contrast: k-contrast-legacy($kendo-color-primary) !default;

/// The secondary color of the theme.
/// @group color-system
/// @type Color
$kendo-color-secondary: #666666 !default;
$kendo-color-secondary-lighter: k-color-tint($kendo-color-secondary, 2) !default;
$kendo-color-secondary-darker: k-color-shade($kendo-color-secondary, 2) !default;

/// The color used along with the secondary color denoted by $kendo-color-secondary.
/// Used to provide contrast between the background and foreground colors.
/// @group color-system
/// @type Color
$kendo-color-secondary-contrast: k-contrast-legacy($kendo-color-secondary) !default;

/// The tertiary color of the theme.
/// @group color-system
/// @type Color
$kendo-color-tertiary: #03a9f4 !default;
$kendo-color-tertiary-lighter: k-color-tint($kendo-color-tertiary, 2) !default;
$kendo-color-tertiary-darker: k-color-shade($kendo-color-tertiary, 2) !default;

/// The color used along with the tertiary color denoted by $kendo-color-tertiary.
/// Used to provide contrast between the background and foreground colors.
/// @group color-system
/// @type Color
$kendo-color-tertiary-contrast: k-contrast-legacy($kendo-color-tertiary) !default;

/// The color for informational messages and states.
/// @group color-system
/// @type Color
$kendo-color-info: #0058e9 !default;
$kendo-color-info-lighter: k-color-tint($kendo-color-info, 2) !default;
$kendo-color-info-darker: k-color-shade($kendo-color-info, 2) !default;

/// The color for success messages and states.
/// @group color-system
/// @type Color
$kendo-color-success: #37b400 !default;
$kendo-color-success-lighter: k-color-tint($kendo-color-success, 2) !default;
$kendo-color-success-darker: k-color-shade($kendo-color-success, 2) !default;

/// The color for warning messages and states.
/// @group color-system
/// @type Color
$kendo-color-warning: #ffc000 !default;
$kendo-color-warning-lighter: k-color-tint($kendo-color-warning, 2) !default;
$kendo-color-warning-darker: k-color-shade($kendo-color-warning, 2) !default;

/// The color for error messages and states.
/// @group color-system
/// @type Color
$kendo-color-error: #f31700 !default;
$kendo-color-error-lighter: k-color-tint($kendo-color-error, 2) !default;
$kendo-color-error-darker: k-color-shade($kendo-color-error, 2) !default;

/// The dark color of the theme.
/// @group color-system
/// @type Color
$kendo-color-dark: #424242 !default;

/// The light color of the theme.
/// @group color-system
/// @type Color
$kendo-color-light: #ebebeb !default;

/// Inverse color of the theme. Depending on the theme luminance dark or light, it will be light or dark
/// @group color-system
$kendo-color-inverse: if($kendo-is-dark-theme, $kendo-color-light, $kendo-color-dark) !default;

$kendo-theme-colors: (
    "primary": $kendo-color-primary,
    "secondary": $kendo-color-secondary,
    "tertiary": $kendo-color-tertiary,
    "info": $kendo-color-info,
    "success": $kendo-color-success,
    "warning": $kendo-color-warning,
    "error": $kendo-color-error,
    "dark": $kendo-color-dark,
    "light": $kendo-color-light,
    "inverse": $kendo-color-inverse,
); // todo: add !default;

// Generic styles

/// Background color of the body.
/// @group common
$kendo-body-bg: $kendo-color-white !default;
/// Text color of the body.
/// @group common
$kendo-body-text: #424242 !default;

/// Subtle text color.
/// @group common
$kendo-subtle-text: #666666 !default;

$kendo-app-bg: k-try-shade($kendo-body-bg, 0.25) !default;
$kendo-app-text: $kendo-body-text !default;
$kendo-app-border: rgba($kendo-color-black, 0.08) !default;

// Link
/// Text color of the links.
/// @group common
$kendo-link-text: $kendo-color-primary !default;
/// Text color of the links on hover.
/// @group common
$kendo-link-hover-text: $kendo-color-primary-darker !default;

// Components

// Component
/// Background color of a component.
/// Note: do not use this variable directly. Instead derive it as `$component-name-bg` e.g. `$kendo-grid-bg: $kendo-component-bg !default;`.
/// @group component
$kendo-component-bg: $kendo-body-bg !default;
/// Text color of a component.
/// Note: do not use this variable directly. Instead derive it as `$component-name-text` e.g. `$kendo-grid-text: $kendo-component-text !default;`.
/// @group component
$kendo-component-text: $kendo-body-text !default;
/// Border color of a component.
/// Note: do not use this variable directly. Instead derive it as `$component-name-border` e.g. `$kendo-grid-border: $kendo-component-border !default;`.
/// @group component
$kendo-component-border: rgba(if($kendo-is-dark-theme, $kendo-color-white, $kendo-color-black), 0.08) !default;

/// The background of the components' chrome area.
$kendo-base-bg: #fafafa !default;
/// The text color of the components' chrome area.
$kendo-base-text: $kendo-body-text !default;
/// The border color of the components' chrome area.
$kendo-base-border: rgba(black, 0.08) !default;
/// The gradient background of the components' chrome area.
$kendo-base-gradient: rgba(black, 0), rgba(black, 0.02) !default;

/// The background of hovered items.
$kendo-hover-bg: k-try-shade($kendo-base-bg, 0.5) !default;
/// The text color of hovered items.
$kendo-hover-text: $kendo-base-text !default;
/// The border color of hovered items.
$kendo-hover-border: $kendo-base-border !default;
/// The gradient background of hovered items.
$kendo-hover-gradient: $kendo-base-gradient !default;

/// The background of selected items.
$kendo-selected-bg: $kendo-color-primary !default;
/// The text color of selected items.
$kendo-selected-text: k-contrast-legacy($kendo-selected-bg) !default;
/// The border color of selected items.
$kendo-selected-border: $kendo-base-border !default;
/// The gradient background of selected items.
$kendo-selected-gradient: $kendo-base-gradient !default;

/// The background of selected and hovered items.
$kendo-selected-hover-bg: k-try-shade($kendo-selected-bg, 0.5) !default;
/// The text color of selected and hovered items.
$kendo-selected-hover-text: $kendo-selected-text !default;
/// The border of selected and hovered items.
$kendo-selected-hover-border: $kendo-base-border !default;
/// The gradient of selected and hovered items.
$kendo-selected-hover-gradient: $kendo-base-gradient !default;

/// Text color of disabled items.
$kendo-disabled-text: #8f8f8f !default;

// Generic styles

/// Background color of the component header.
/// @group component
$kendo-component-header-bg: $kendo-base-bg !default;
/// Text color of the component header.
/// @group component
$kendo-component-header-text: $kendo-base-text !default;
/// Border color of the component header.
/// @group component
$kendo-component-header-border: $kendo-base-border !default;
/// Gradient of the component header.
/// @group component
$kendo-component-header-gradient: null !default;

/// Background color of the invalid items.
/// @group component
$kendo-invalid-bg: null !default;
/// Text color of the invalid items.
/// @group component
$kendo-invalid-text: $kendo-color-error !default;
/// Border color of the invalid items.
/// @group component
$kendo-invalid-border: $kendo-color-error !default;
/// Shadow of the invalid items.
/// @group component
$kendo-invalid-shadow: null !default;

/// Background color of the valid items.
/// @group component
$kendo-valid-bg: null !default;
/// Text color of the valid items.
/// @group component
$kendo-valid-text: $kendo-color-success !default;
/// Border color of the valid items.
/// @group component
$kendo-valid-border: $kendo-color-success !default;
/// Shadow of the valid items.
/// @group component
$kendo-valid-shadow: null !default;
