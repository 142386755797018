@mixin kendo-map--layout-base() {

    // Map
    .k-map {
        height: 600px;
        box-sizing: border-box;
        border-width: $kendo-map-border-width;
        border-style: solid;
        font-size: $kendo-map-font-size;
        line-height: $kendo-map-line-height;
        font-family: $kendo-map-font-family;

        *,
        *::before,
        *::after {
            box-sizing: border-box;
        }


        // Scroll
        .km-scroll-wrapper {
            width: 100%;
            height: 100%;
            user-select: none;
            position: absolute;
        }
        .km-scroll-container { height: 100%; }
        .k-touch-scrollbar { display: none; }

        // Layers
        .k-layer {
            position: absolute;
            left: 0;
            top: 0;
        }

        // Marker
        .k-marker {
            transform: translate(-50%, -100%);
            cursor: pointer;
            position: absolute;
            overflow: visible;
        }


        // Attribution
        .k-attribution {
            padding-block: $kendo-map-attribution-padding-y;
            padding-inline: $kendo-map-attribution-padding-x;
            border-width: 0;
            font-size: $kendo-map-attribution-font-size;
            z-index: 1000;
        }


    }


    // Controls
    .k-map-controls {
        position: absolute;
        display: flex;
        align-items: center;
    }


    // Navigator
    .k-navigator {
        margin: $kendo-map-navigator-margin;
        width: $kendo-map-navigator-width;
        height: $kendo-map-navigator-width;
        border-width: $kendo-map-navigator-border-width;
        border-style: solid;
        box-sizing: border-box;
        border-radius: 50%;
        position: relative;

        .k-pdf-export & { display: none; }


        // Buttons
        .k-button {
            padding: 0;
            width: auto;
            height: auto;
            line-height: 1;
            box-shadow: none;
            position: absolute;

            .k-icon {
                min-width: 0;
                min-height: 0;
            }
        }
        .k-navigator-n,
        .k-navigator-up {
            transform: translateX(-50%);
            top: $kendo-map-navigator-padding;
            left: 50%;
        }
        .k-navigator-e,
        .k-navigator-right {
            transform: translateY(-50%);
            right: $kendo-map-navigator-padding;
            top: 50%;
        }
        .k-navigator-s,
        .k-navigator-down {
            transform: translateX(-50%);
            bottom: $kendo-map-navigator-padding;
            left: 50%;
        }
        .k-navigator-w,
        .k-navigator-left {
            transform: translateY(-50%);
            left: $kendo-map-navigator-padding;
            top: 50%;
        }
    }


    // Zoom control
    .k-zoom-control {
        margin: k-map-get( $kendo-spacing, 4 );
        border: 0;
        background: none;
        display: flex;

        .k-pdf-export & { display: none; }
    }

}


@mixin kendo-map--layout() {
    @include kendo-map--layout-base();
}
