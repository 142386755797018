// Captcha

/// The spacer of the Captcha.
/// @group captcha
$kendo-captcha-spacer: k-map-get( $kendo-spacing, 2 ) !default;

/// The width of the Captcha.
/// @group captcha
$kendo-captcha-width: 280px !default;
/// The font family of the Captcha.
/// @group captcha
$kendo-captcha-font-family: $kendo-font-family !default;
/// The font size of the Captcha.
/// @group captcha
$kendo-captcha-font-size: $kendo-font-size-md !default;
/// The line height of the Captcha.
/// @group captcha
$kendo-captcha-line-height: $kendo-line-height-md !default;
/// The gap of the Captcha.
/// @group captcha
$kendo-captcha-gap: $kendo-captcha-spacer !default;
/// The background color of the Captcha.
/// @group captcha
$kendo-captcha-bg: $kendo-component-bg !default;
/// The text color of the Captcha.
/// @group captcha
$kendo-captcha-text: $kendo-component-bg !default;
/// The border color of the Captcha.
/// @group captcha
$kendo-captcha-border: $kendo-component-bg !default;

/// The gap of the Captcha image wrapper.
/// @group captcha
$kendo-captcha-image-wrap-gap: $kendo-captcha-spacer !default;
/// The gap of the Captcha image controls.
/// @group captcha
$kendo-captcha-image-controls-gap: $kendo-captcha-spacer !default;

/// The top margin of the Captcha validation message.
/// @group captcha
$kendo-captcha-validation-message-margin-top: k-math-div( $kendo-captcha-spacer, 2 ) !default;
/// The font size of the Captcha validation message.
/// @group captcha
$kendo-captcha-validation-message-font-size: $kendo-font-size-sm !default;
/// The font style of the Captcha validation message.
/// @group captcha
$kendo-captcha-validation-message-font-style: italic !default;
