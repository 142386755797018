// ListBox

/// The spacing between the ListBox elements.
/// @group listbox
$kendo-listbox-spacing: k-map-get( $kendo-spacing, 2 ) !default;
/// The spacing between the ListBox buttons.
/// @group listbox
$kendo-listbox-button-spacing: k-map-get( $kendo-spacing, 2 ) !default;
/// The width of the ListBox.
/// @group listbox
$kendo-listbox-width: 10em !default;
/// The height of the ListBox.
/// @group listbox
$kendo-listbox-default-height: 200px !default;
/// The width of the border around the ListBox.
/// @group listbox
$kendo-listbox-border-width: 1px !default;
/// The font family of the ListBox.
/// @group listbox
$kendo-listbox-font-family: $kendo-font-family !default;
/// The font size of the ListBox.
/// @group listbox
$kendo-listbox-font-size: $kendo-font-size-md !default;
/// The line height of the ListBox.
/// @group listbox
$kendo-listbox-line-height: $kendo-line-height-md !default;

/// The text color of the ListBox.
/// @group listbox
$kendo-listbox-text: $kendo-component-text !default;
/// The background color of the ListBox.
/// @group listbox
$kendo-listbox-bg: $kendo-component-bg !default;
/// The border color of the ListBox.
/// @group listbox
$kendo-listbox-border: $kendo-component-border !default;

/// The inline padding of the ListBox item.
/// @group listbox
$kendo-listbox-item-padding-x: null !default;
/// The block padding of the ListBox item.
/// @group listbox
$kendo-listbox-item-padding-y: null !default;

/// The width of the ListBox drop hint.
/// @group listbox
$kendo-listbox-drop-hint-width: 1px !default;
/// The width of the border around the ListBox drop hint.
/// @group listbox
$kendo-listbox-drop-hint-border-width: null !default;