/* You can add global styles to this file, and also import other style files */
/* Importing Bootstrap SCSS file. */
// @import '~bootstrap/scss/bootstrap';

/* You can add global styles to this file, and also import other style files */
// @import '~dropzone/dist/min/dropzone.min.css';
// @import "@progress/kendo-theme-default/scss/dist/all.scss";

// @import "@progress/kendo-theme-bootstrap/dist/all.scss";
// @import "~bootstrap/scss/bootstrap.scss";

$kendo-color-primary: #2387C8;


@import "@progress/kendo-theme-default/scss/all.scss";

@import 'dropzone/dist/min/dropzone.min.css';


.modal-holder {
  animation-name: example;
  animation-duration: 0.3s;
}

@keyframes example {
  0% {
    transform: scale(0.5)
  }

  100% {
    transform: scale(1)
  }
}

.carousel-item {
  transition: opacity 0.7s ease !important;
  position: absolute !important;
  display: block !important;
  opacity: 0;
}

.cursor-pointer {
  cursor: pointer;
}

.carousel-item.active {
  position: relative !important;
  opacity: 1;
}

.position-relative {
  position: relative;
}


.page-title-box {
  padding-bottom: 15px !important;
}

.font-weight-bold-dashboard {
  font-weight: 600;
  font-size: 22px;
}

.mini-stats-wid-dashboard {
  margin-top: 30px;

  .card-details {
    margin-top: 2.5rem;
  }
}

.fr {
  float: right
}

.page-title-box .breadcrumb {
  font-size: 14px !important;
}

.page-content {
  padding: calc(70px + 10px) calc(24px* 0.75) 60px calc(24px* 0.75) !important;
}

ngx-dropzone {
  border: 2px dashed var(--bs-border-color) !important;
}


// Kendo Grid Css //

.k-header {
  background: $kendo-color-primary !important;
  color: #fff !important;
}

.k-grid-header-menu {
  color: #fff !important;
}

.k-grid-header .k-grid-header-menu:hover {
  background: $kendo-color-primary !important;
}

// Kendo Grid Css End //

// Custom stepper Css //

.custom-steeper .stepper .stepper-header .steps {
  width: 50% !important;
}

.custom-steeper .stepper .stepper-body {
  padding: 0rem !important;
}

.custom-steeper-paddding .stepper .stepper-body {
  padding: 0rem !important;
}

.stepper .stepper-body {
  margin: 15px auto !important;
  padding: 0px !important;
}

// Custom stepper Css End //


// Breadcrumb Css //

.breadcrumb-item.active {
  font-weight: 600 !important;
}

// Breadcrumb Css End //

// Accordian Css //

.custom-accordian .panel.accordion-item .accordion-toggle {
  display: flex;
  justify-content: space-between;
}

.custom-accordian .panel .card .card-header {
  padding: 5px 20px !important;
}

.panel.accordion-item.panel-open .card-header {
  background-color: #EEF1FD !important;
}


.custom-accordian-bill .panel.accordion-item.panel-open .card-header {
  background-color: #fff !important;
}



.panel-group .card .card-header {
  padding: 5px 20px !important;
}

.panel.accordion-item.panel-open {

  .btn-link {
    color: $kendo-color-primary !important;
    margin-top: 5px;
    font-size: 1rem;
  }

  .panel-title {
    color: $kendo-color-primary !important;

  }

}

.text-accordian {
  color: #626262 !important;
}

.custom-accordian-bill .panel .panel-default {
  box-shadow: 0px 2px 12px 2px #E5E9FC
}

.custom-accordian-bill .panel .card .card-header {
  padding-left: 8px !important;
  padding-right: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

// Accodian Css End //

//
// Dropzone
//

.dropzone {
  min-height: 230px !important;
  border: 2px dashed #eff2f7 !important;
  background: #fff !important;
  border-radius: 6px !important;
  text-align: center;

  .dz-message {
    width: 100%;
    margin: 1em 0;
  }
}

[data-dz-thumbnail] {
  width: 100%;
  height: 100%;
}

.dropzone .dz-preview .dz-image {
  border-radius: 20px;
  overflow: hidden;
  width: 120px;
  height: 120px;
  position: relative;
  display: block;
  z-index: 10;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dropzone .dz-preview .dz-image img {
  display: block;
  height: 90px;
  width: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dropzone .dz-preview .dz-remove {
  margin-top: 10px;
}

dropzone>.dropzone.dz-wrapper .dz-message {
  background-color: transparent !important;
  border: none !important;
  overflow: inherit !important;
  width: inherit !important;
  min-width: inherit !important;
  min-height: 180px !important;
  max-width: inherit !important;
  max-height: inherit !important;
  margin: 0px !important;
}

.dz-text {
  font-size: 1.21875rem
}

dropzone>.dropzone.dz-wrapper {
  min-height: 76px !important;
  border: none !important;
}


#component-colorpicker {

  color-picker {
    .open {
      right: 0px !important;
      left: auto !important;
      z-index: 9999 !important;
    }
  }
}

.color-picker {
  .arrow.arrow-bottom {
    right: 20px !important;
    left: auto !important;
  }
}

.switch.switch-small>.switch-pane>span {
  font-size: 12px !important;
}

.modal-dialog {
  top: auto !important;
  bottom: auto !important;
}

.slick-dots {
  margin-top: 20px;
  bottom: -40px !important;


  li.slick-active button:before {
    color: $kendo-color-primary !important;
    font-size: x-small !important;
  }
}

.slick-dots li button:before {
  color: $kendo-color-primary;
  font-size: x-small !important;
}

.slick-slide img {
  width: 100%;
}

.dropup {
  .dropdown-menu {
    inset: auto auto auto 0px !important;
    transform: translateY(-160px) !important;
  }
}

.ngx-slider .ngx-slider-tick.ngx-slider-selected {
  background-color: $kendo-color-primary !important;
}

.w-60 {
  width: 60% !important;
}

.navbar-brand-box {
  padding: 0rem !important;
}

.c-mr-1 {
  margin-right: 0.5rem;
}

.mdi-attachment-rotate {
  transform: rotate(315deg);
}

.cursor-move {
  cursor: move;
}

.cursor-pointer {
  cursor: pointer;
}

.c-border {
  border: 1px solid #fff;
}

.custom-calender-icon {
  position: absolute;
  top: 40px;
  right: 25px;
}

.custom-grid-btn {
  padding: 5px 10px !important;
  display: flex !important;
  align-items: baseline !important;
}

.btn-white {
  background-color: #fff;
  color: #000;
  width: 100%;
}

.btn-white:hover {
  background: #efefef;
  color: #000;
}

.utility-img {
  background: #EEF1FD;
  padding: 5px;
  width: 30px;
  border-radius: 5px;
  margin-right: 10px;

}

.custom-bg-body {
  background-color: var(--bs-body-bg);
}

.w-3 {
  width: 3%;
  height: 0%;
}

.w-80 {
  width: 80%;
}

.form-label {
  margin-bottom: 0.2rem !important;
}

.custom-table {
  border: 1px solid #A6B0CF !important;

  thead tr th {
    background-color: #EEF1FD;
  }

  tbody tr td {
    background-color: #EEF1FD;
  }
}

.custom-list-bill {
  background: #EEF1FD;
  border: 1px solid #A6B0CF !important;

  .custom-padding {
    padding: 0px 15px 0px 15px;
  }
}

.custom-list-bill-danger {
  background: #ffe4e4;
  border: 1px solid #f68585 !important;

  .custom-padding {
    padding: 0px 15px 0px 15px;
  }
}

.custom-list-bill-view {
  background: #E6F4F1;
}

.border-right {
  border-right: 1px solid #A6B0CF !important;
}

.bg-grey {
  background-color: #8D8DB9 !important;
}

.height-25 {
  height: 25px;
}

.underline {
  border-bottom: 2px solid $kendo-color-primary;
  margin: 3px 0px 10px 0px !important
}

.underline-danger {
  border-bottom: 2px solid rgba(244, 106, 106, 1);
  margin: 3px 0px 10px 0px !important
}

.mt-8 {
  margin-top: 8.4rem
}

@media only screen and (max-width: 600px) {
  .logo-sm {
    padding: 0px 5px 0px 5px !important;
  }

  .page-title-right {
    margin-top: 10px;
  }
}

// Media Query For Mobile Devices //

@media (max-width: 992px) {
  .logo span.logo-sm {
    display: none !important;
  }
}

@media screen and (max-width: 767px) {

  .k-grid .k-grid-aria-root {
    overflow: auto !important;
  }

  .k-grid .k-table {
    table-layout: auto !important;
  }

  .custom-steeper .stepper .stepper-header .steps {
    width: 100% !important;
  }

  .sm-btn {
    margin-bottom: 1rem;
    justify-content: end !important;
  }

  .sm-device {
    display: flex;
    justify-content: end;
    width: 100%;
  }

  .page-title-right {
    margin-top: 1rem;
  }

  // Stepper Css //

  .stepper-body {
    margin: 5px auto !important;
    padding: 0.875rem !important;
  }

  .sm-stepper .stepper .stepper-header {
    display: none !important;
  }

}

// Media Query For Mobile Devices //


@media print {

  .print-bottom {
    position: fixed;
    bottom: 0;
    width: 100%;
  }

  // .content-view {
  //   page-break-after: always
  // }

  //   .content-view:last-child {
  //     page-break-after: auto;
  // }


  .print-multi-bottom {
    position: relative;
    bottom: 0;
    width: 100%;
    text-align: center;
  }


  @page: first {
    @bottom-left {
      content: none;
    }

    @bottom-center {
      content: none;
    }

    @bottom-right {
      content: none;
    }

  }

  // .print-multi-bottom {
  //   display: block; 
  // }


  .bg-grey {
    background-color: #8D8DB9 !important;
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }

  .custom-list-bill-view {
    background: #E6F4F1;
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }

  .custom-list-bill {
    background: #EEF1FD;
    border: 1px solid #A6B0CF !important;
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }

  .bg-primary {
    background: $kendo-color-primary !important;
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }

  .col-lg-6 {
    width: 50%;
  }

  html {
    overflow: hidden;
  }
}

// Kendo Dropdown CSS //

.kendo-form-control .button {
  background-color: var(--bs-secondary-bg);
}

.kendo-form-control .k-input-inner {

  width: 100%;
  padding: 0.47rem 0.75rem !important;
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-emphasis-color);
  appearance: none;
  background-color: var(--bs-secondary-bg);
  background-clip: padding-box;
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

}

// Kendo Dropdown CSS End //

.mt-50 {
  margin-top: 50%;
}

.mt-100 {
  margin-top: 100%;
}



.app-search .form-control {
  border: none;
  height: 38px;
  padding-left: 40px;
  padding-right: 20px;
  background-color: #e8e8eb !important;
  box-shadow: none;
  border-radius: 0.25rem !important;
}

.app-search {
  padding: calc(12px * 0.5) 0 !important;
}

.text-blue {
  color: #2387C8;
}

.border-bottom {
  border-bottom: 1px solid #2387C8;
  padding-bottom: 10px;
}

.k-grid-header .k-sort-icon {
  color: white !important;
  /* Change to your desired color */
}

.k-grid-header .k-grid-filter.k-active,
.k-grid-header .k-header-column-menu.k-active,
.k-grid-header .k-grid-header-menu.k-active,
.k-grid-header .k-hierarchy-cell .k-icon.k-active {
  color: #8c8c8c !important;
  background-color: #fcfcfd !important;
}

.images-view img {
  // width: 10%;
}

.mini-stats-wid-border {
  border: 2px dashed #50A9D1;
}


.font-weight-bolder {
  font-weight: 500;
}

.project-image {
  width: 15%;
  margin-right: 5px;
}

.dashboard-image {
  width: 80%;
}

.dateRange {
  padding: 0.35rem 0.75rem !important;


}

.input-container {
  position: relative;
  display: inline-block;
}

.input-container input {
  padding-right: 30px;
}

.input-container::after {
  content: '\e9c2';
  position: absolute;
  font-family: boxicons !important;
  font-weight: 400;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  cursor: pointer;
}

.border-radius-sm {
  border-radius: 8px;
}

.font-weight-bold {
  font-size: 20px !important;
  font-weight: 600 !important;
}

.rp-border-right {
  border-right: 2px dashed #50A9D1;
}

.rp-icon {
  width: 30%;
  background: white;
  padding: 4px;
  border-radius: 50%;
}

.bg-gas-subtle {
  background-color: #FFE2E5;
}

.bg-electricity-subtle {
  background-color: #FFF4DE;
}

.bg-water-subtle {
  background-color: #F3E8FF;
}

.bg-subtle {
  background: #FFF8F9;
  border: 1px solid #FFE2E5;
  border-radius: 3px;
}

.hr {
  border-style: solid;
  border-width: thin;
  border-color: #e0e0e0;
  margin: 0 !important;
}

.mt-custom {
  margin-bottom: 17rem
}

.hr-center {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #d7d7d7;
  line-height: 0.1em;
  margin: 20px 0 0px;

}

.hr-center span {
  background: #fff;
  padding: 0 10px;
}

.modal-content {
  border: none !important;
}

.k-grid td {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.swal2-icon {
  font-size: 7px !important;
}

.swal2-title {
  // width: 31%;
  width: unset !important;
}

.custom-border-table {
  border: 1px solid #c0f8ec; // Set the border color and thickness
  margin-top: 2rem;

  // th, td {
  //   border: 1px solid transparent;  // Optional: Add border to individual cells
  // }

  thead {
    tr:first-child {
      border-bottom: 1px solid #c0f8ec;

    }

    tbody {

      tr:first-child {
        border-bottom: 1px solid transparent !important;
      }

      tr:nth-child(2) {
        border-bottom: 1px solid #c0f8ec;
      }
    }
  }
}

.totalBill {
  border: 1px solid #c0f8ec;
  padding: 7px;
  border-radius: 5px;
}

.font-weight-100 {
  font-weight: 100 !important;
}

.penaltyBill {
  padding: 20px;
  background: #FFE2E5;
  border: 2px solid #e99d9d;
  border-radius: 5px;
  margin-top: 1rem;

  div p {
    margin-bottom: 0px;
  }
}

.page-title-box {
  padding-bottom: 15px !important;
}

.page-title-box .breadcrumb {
  font-size: 14px !important;
}

.page-content {
  padding: calc(70px + 10px) calc(24px* 0.75) 60px calc(24px* 0.75) !important;
}

.breadcrumb {
  .btn-primary {
    .fa-file-excel {
      margin-right: 5px;
      font-size: 14px;
    }
  }
}

label {
  font-size: 15px;
}

.border-left-none {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.right-side-panel {
  position: fixed;
  top: 4.5rem;
  right: 0px;
  height: 100%;
  width: 325px;
  background-color: #fff;
  box-shadow: 0px 0 1px rgba(0, 0, 0, 0.5);
  // transition: right 0.3s;
  cursor: pointer;
  z-index: 111;
  transition: 0.5s ease;
}

.right-side-panel .content {
  flex-grow: 1;
  overflow-y: auto;
}

.sidemenuimages {
  width: 20px;
  padding: 2px;
}










.right-side-panel {
  width: 350px !important;
  background-color: white !important;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1) !important;
}

td.k-detail-cell.k-table-td {
  padding: 0 !important;
}

td.k-detail-cell.k-table-td app-wallet-history-expended {
  padding: initial;
  /* Or set any required padding for the content inside */
}

// .right-side-panel .notification-item {
//   transition: background-color 0.2s ease-in-out !important;
// }

// .right-side-panel .notification-item:hover {
//   background-color: #f1f3f5 !important;
// }

.h-290 {
  height: 290px !important;
}

.h-250 {
  height: 250px;
}

.h-275 {
  height: 275px;
}

.k-grid .k-grid-content td {
  white-space: nowrap;
  /* Prevent text from wrapping */
  overflow: hidden;
  /* Hide overflow text */
  text-overflow: ellipsis;
  /* Add ellipsis for overflow text */
  max-width: 150px;
  /* Set a max width for the cells */
}


.btn-light.k-button-solid-base {
  background-color: #fff !important;
  border-color: $kendo-color-primary !important;
  color: $kendo-color-primary !important;
  padding: 0.25rem 0.5rem !important;
  font-size: 0.7109375rem !important;
  border-radius: 0.2rem !important;

  &:hover {
    background-color: $kendo-color-primary !important;
    border-color: $kendo-color-primary !important;
    color: #fff !important;
  }



}

.stepper-header .steps .step.active {
  font-size: 1.1rem;
  color: #2387C8;
  border-bottom: 3px solid;
  border-left: 1px solid #ccc !important;
  border-right: 1px solid #ccc !important;
  box-shadow: -4px 4px 5px -4px #ccc, 0px -7px 7px 0px #ccc;
  cursor: pointer;
}

.wizard .stepper-header>ol>li {
  padding: 0.8rem 1rem !important;
}

.wizard .stepper-header .number {
  display: none;
}

#tableGrid {
  .k-table-td:hover {
    cursor: pointer !important;
    text-decoration: underline;
    text-decoration-color: #2387C8;
    background-color: #bad5e6;
  }

  .k-table-td {
    text-align: center !important;
  }

  th.headerCustomClass {
    justify-content: center;
  }
}

#tableGrid .k-grid-content table td[data-kendo-grid-column-index="0"] {
  /* assuming the 'Date' column is at index 0 */
  cursor: default !important;
  text-align: center !important;
  //background-color: #ebebf4;
}

#tableGrid .k-grid-content table td[data-kendo-grid-column-index="0"]:hover {
  text-decoration: none !important;
  /* Remove underline */
  text-decoration-color: transparent !important;
  /* Remove the underline color */
  background-color: inherit !important;
  /* Keep the original background color */
}

/* Center the header text */

.prepaid-dashboard-image {
  background: #fff;
  border-radius: 50%;
  z-index: 1;
  box-shadow: 0 2px 7px 1px rgba(0, 0, 0, 0.25) !important;
  height: 70px;
  width: 70px;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 50px;
  }
}