// Avatar

/// The border width of the Avatar.
/// @group avatar
$kendo-avatar-border-width: 1px !default;

/// The font family of the Avatar.
/// @group avatar
$kendo-avatar-font-family: $kendo-font-family !default;
/// The font size of the Avatar.
/// @group avatar
$kendo-avatar-font-size: $kendo-font-size-md !default;
/// The line height of the Avatar.
/// @group avatar
$kendo-avatar-line-height: $kendo-line-height-md !default;

/// The sizes map of the Avatar.
/// @group avatar
$kendo-avatar-sizes: (
    sm: k-map-get( $kendo-spacing, 4 ),
    md: k-map-get( $kendo-spacing, 8 ),
    lg: k-map-get( $kendo-spacing, 16 )
) !default;

/// The theme colors map of the Avatar.
/// @group avatar
$kendo-avatar-theme-colors: $kendo-theme-colors !default;
