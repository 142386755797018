@mixin kendo-typography--layout-base() {

    @if $kendo-enable-typography {
        body { @extend .k-body !optional; }
        h1 { @extend .k-h1 !optional; }
        h2 { @extend .k-h2 !optional; }
        h3 { @extend .k-h3 !optional; }
        h4 { @extend .k-h4 !optional; }
        h5 { @extend .k-h5 !optional; }
        h6 { @extend .k-h6 !optional; }
        p { @extend .k-paragraph !optional; }
        pre { @extend .k-pre !optional; }
        code { @extend .k-code !optional; }
    }

    // TODO delete k-typography class for R1 2022
    .k-body,
    .k-typography {
        @include typography(
            $kendo-font-size-md,
            $kendo-font-family,
            $kendo-line-height-md,
            $kendo-font-weight-normal,
            $kendo-letter-spacing
        );
        margin: 0;

        p {
            margin: $kendo-paragraph-margin;
        }
    }

    // Headings
    .k-h1 {
        @include typography(
            $kendo-h1-font-size,
            $kendo-h1-font-family,
            $kendo-h1-line-height,
            $kendo-h1-font-weight,
            $kendo-h1-letter-spacing
        );
        margin: $kendo-h1-margin;
    }
    .k-h2 {
        @include typography(
            $kendo-h2-font-size,
            $kendo-h2-font-family,
            $kendo-h2-line-height,
            $kendo-h2-font-weight,
            $kendo-h2-letter-spacing
        );
        margin: $kendo-h2-margin;
    }
    .k-h3 {
        @include typography(
            $kendo-h3-font-size,
            $kendo-h3-font-family,
            $kendo-h3-line-height,
            $kendo-h3-font-weight,
            $kendo-h3-letter-spacing
        );
        margin: $kendo-h3-margin;
    }
    .k-h4 {
        @include typography(
            $kendo-h4-font-size,
            $kendo-h4-font-family,
            $kendo-h4-line-height,
            $kendo-h4-font-weight,
            $kendo-h4-letter-spacing
        );
        margin: $kendo-h4-margin;
    }
    .k-h5 {
        @include typography(
            $kendo-h5-font-size,
            $kendo-h5-font-family,
            $kendo-h5-line-height,
            $kendo-h5-font-weight,
            $kendo-h5-letter-spacing
        );
        margin: $kendo-h5-margin;
    }
    .k-h6 {
        @include typography(
            $kendo-h6-font-size,
            $kendo-h6-font-family,
            $kendo-h6-line-height,
            $kendo-h6-font-weight,
            $kendo-h6-letter-spacing
        );
        margin: $kendo-h6-margin;
    }

    // Paragraph
    .k-paragraph {
        @include typography(
            $kendo-paragraph-font-size,
            $kendo-paragraph-font-family,
            $kendo-paragraph-line-height,
            $kendo-paragraph-font-weight,
            $kendo-paragraph-letter-spacing
        );
        margin: $kendo-paragraph-margin;
    }

    // Code snippet
    .k-pre,
    .k-code {
        @include typography(
            $kendo-code-font-size,
            $kendo-code-font-family,
            $kendo-code-line-height,
            $kendo-code-font-weight,
            $kendo-code-letter-spacing
        );
        border-style: solid;
        border-width: $kendo-code-border-width;
    }

    .k-pre {
        padding-block: $kendo-pre-padding-y;
        padding-inline: $kendo-pre-padding-x;
        overflow: auto;
    }

    .k-pre > .k-code {
        padding: 0;
        border-width: 0;
        border-color: transparent;
        background-color: transparent;
        font-family: inherit;
        font-size: inherit;
    }

    .k-code {
        padding-block: $kendo-code-padding-y;
        padding-inline: $kendo-code-padding-x;
    }

    // Display
    .k-display-1 {
        @include typography(
            $kendo-display1-font-size,
            $kendo-display1-font-family,
            $kendo-display1-line-height,
            $kendo-display1-font-weight,
            $kendo-display1-letter-spacing
        );
    }
    .k-display-2 {
        @include typography(
            $kendo-display2-font-size,
            $kendo-display2-font-family,
            $kendo-display2-line-height,
            $kendo-display2-font-weight,
            $kendo-display2-letter-spacing
        );
    }
    .k-display-3 {
        @include typography(
            $kendo-display3-font-size,
            $kendo-display3-font-family,
            $kendo-display3-line-height,
            $kendo-display3-font-weight,
            $kendo-display3-letter-spacing
        );
    }
    .k-display-4 {
        @include typography(
            $kendo-display4-font-size,
            $kendo-display4-font-family,
            $kendo-display4-line-height,
            $kendo-display4-font-weight,
            $kendo-display4-letter-spacing
        );
    }

}


@mixin kendo-typography--layout() {
    @include kendo-typography--layout-base();
}
