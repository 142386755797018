// ScrollView

/// The width of the border around the ScrollView.
/// @group scrollview
$kendo-scrollview-border-width: 1px !default;
/// The font family of the ScrollView.
/// @group scrollview
$kendo-scrollview-font-family: $kendo-font-family !default;
/// The font size of the ScrollView.
/// @group scrollview
$kendo-scrollview-font-size: $kendo-font-size-md !default;
/// The line height of the ScrollView.
/// @group scrollview
$kendo-scrollview-line-height: $kendo-line-height-md !default;

/// The text color of the ScrollView.
/// @group scrollview
$kendo-scrollview-text: $kendo-component-text !default;
/// The background color of the ScrollView.
/// @group scrollview
$kendo-scrollview-bg: $kendo-component-bg !default;
/// The border color of the ScrollView.
/// @group scrollview
$kendo-scrollview-border: $kendo-component-border !default;

/// The size of the ScrollView page button.
/// @group scrollview
$kendo-scrollview-pagebutton-size: 10px !default;
/// The background color of the ScrollView page button.
/// @group scrollview
$kendo-scrollview-pagebutton-bg: $kendo-button-bg !default;
/// The border color of the ScrollView page button.
/// @group scrollview
$kendo-scrollview-pagebutton-border: $kendo-button-border !default;
/// The primary background color of the ScrollView page button.
/// @group scrollview
$kendo-scrollview-pagebutton-primary-bg: $kendo-color-primary !default;
/// The primary border color of the ScrollView page button.
/// @group scrollview
$kendo-scrollview-pagebutton-primary-border: $kendo-color-primary !default;
/// The box shadow of the ScrollView page button.
/// @group scrollview
$kendo-scrollview-pagebutton-shadow: 0 0 0 2px rgba(0, 0, 0, .13) !default;

/// The offset of the ScrollView pager.
/// @group scrollview
$kendo-scrollview-pager-offset: 0 !default;
/// The spacing between the ScrollView pager items.
/// @group scrollview
$kendo-scrollview-pager-item-spacing: 20px !default;
/// The border width of the ScrollView pager items.
/// @group scrollview
$kendo-scrollview-pager-item-border-width: 0px !default;
/// The height of the ScrollView pager.
/// @group scrollview
$kendo-scrollview-pager-height: calc( #{$kendo-scrollview-pagebutton-size} + #{$kendo-scrollview-pager-item-border-width * 2} + #{$kendo-scrollview-pager-item-spacing * 2} ) !default;

/// The text color of the highlight over the tapped ScrollView navigation arrows.
/// @group scrollview
$kendo-scrollview-arrow-tap-highlight-color: $kendo-color-rgba-transparent !default;
/// The color of the ScrollView navigation arrows.
/// @group scrollview
$kendo-scrollview-navigation-color: white !default;
/// The box shadow of the ScrollView navigation arrows.
/// @group scrollview
$kendo-scrollview-navigation-icon-shadow: rgba(0, 0, 0, .3) 0 0 15px !default;
/// The background color of the ScrollView navigation.
/// @group scrollview
$kendo-scrollview-navigation-bg: rgba(0, 0, 0, 0) !default;
/// The opacity of the ScrollView navigation.
/// @group scrollview
$kendo-scrollview-navigation-default-opacity: .7 !default;
/// The hover opacity of the ScrollView navigation.
/// @group scrollview
$kendo-scrollview-navigation-hover-opacity: 1 !default;
/// The hover background color of the ScrollView navigation arrows.
/// @group scrollview
$kendo-scrollview-navigation-hover-span-bg: null !default;

/// The background color of the ScrollView pager in light mode.
/// @group scrollview
$kendo-scrollview-light-bg: rgba(255, 255, 255, .4) !default;
/// The background color of the ScrollView pager in dark mode.
/// @group scrollview
$kendo-scrollview-dark-bg: rgba(0, 0, 0, .4) !default;

/// The duration of the ScrollView transition.
/// @group scrollview
$kendo-scrollview-transition-duration: .3s !default;
/// The timing function of the ScrollView transition.
/// @group scrollview
$kendo-scrollview-transition-timing-function: ease-in-out !default;
