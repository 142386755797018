// Drawer
$kendo-drawer-bg: $kendo-component-bg !default;
$kendo-drawer-text: $kendo-component-text !default;
$kendo-drawer-border: $kendo-component-border !default;
$kendo-drawer-border-width: 1px !default;
$kendo-drawer-font-family: $kendo-font-family !default;
$kendo-drawer-font-size: $kendo-font-size-md !default;
$kendo-drawer-line-height: $kendo-line-height-md !default;
$kendo-drawer-content-padding-x: $kendo-padding-md-x !default;
$kendo-drawer-content-padding-y: $kendo-padding-md-y !default;

$kendo-drawer-scrollbar-width: 7px !default;
$kendo-drawer-scrollbar-color: rgba(156, 156, 156, .7) !default;
$kendo-drawer-scrollbar-bg: #dedede !default;
$kendo-drawer-scrollbar-radius: 20px !default;
$kendo-drawer-scrollbar-hover-color: rgba(156, 156, 156, 1) !default;

$kendo-drawer-item-padding-x: k-map-get( $kendo-spacing, 4 ) !default;
$kendo-drawer-item-padding-y: $kendo-padding-md-x !default;
$kendo-drawer-item-font-size: 16px !default;
$kendo-drawer-item-line-height: $kendo-line-height-lg !default;

$kendo-drawer-item-level-padding-x: $kendo-drawer-item-padding-x !default;
$kendo-drawer-item-level-count: 5 !default;

$kendo-drawer-icon-padding-x: 0 !default;
$kendo-drawer-icon-padding-y: k-map-get( $kendo-spacing, 1 ) !default;

$kendo-drawer-mini-initial-width: calc(2 * #{$kendo-drawer-item-padding-x} + #{$kendo-icon-size}) !default;

$kendo-drawer-hover-bg: $kendo-hover-bg !default;
$kendo-drawer-hover-text: $kendo-hover-text !default;

$kendo-drawer-focus-bg: $kendo-drawer-bg !default;
$kendo-drawer-focus-shadow: $kendo-list-item-focus-shadow !default;

$kendo-drawer-selected-bg: $kendo-selected-bg !default;
$kendo-drawer-selected-text: $kendo-selected-text !default;
$kendo-drawer-selected-hover-bg: $kendo-selected-hover-bg !default;
$kendo-drawer-selected-hover-text: $kendo-selected-hover-text !default;
