@import "./images/image-default.scss";


// Spreadsheet

/// The width of the border around the Spreadsheet.
/// @group spreadsheet
$kendo-spreadsheet-border-width: 1px !default;
/// The font family of the Spreadsheet.
/// @group spreadsheet
$kendo-spreadsheet-font-family: $kendo-font-family !default;
/// The font size of the Spreadsheet.
/// @group spreadsheet
$kendo-spreadsheet-font-size: $kendo-font-size-md !default;
/// The line height of the Spreadsheet.
/// @group spreadsheet
$kendo-spreadsheet-line-height: $kendo-line-height-md !default;

/// The background color of the Spreadsheet.
/// @group spreadsheet
$kendo-spreadsheet-bg: $kendo-component-bg !default;
/// The text color of the Spreadsheet.
/// @group spreadsheet
$kendo-spreadsheet-text: $kendo-component-text !default;
/// The border color of the Spreadsheet.
/// @group spreadsheet
$kendo-spreadsheet-border: $kendo-component-border !default;

/// The background color of the Spreadsheet header.
/// @group spreadsheet
$kendo-spreadsheet-header-bg: $kendo-component-header-bg !default;
/// The text color of the Spreadsheet header.
/// @group spreadsheet
$kendo-spreadsheet-header-text: $kendo-component-header-text !default;
/// The border color of the Spreadsheet header.
/// @group spreadsheet
$kendo-spreadsheet-header-border: $kendo-component-header-border !default;
/// The gradient of the Spreadsheet header.
/// @group spreadsheet
$kendo-spreadsheet-header-gradient: $kendo-component-header-gradient !default;

/// The background color of the Spreadsheet table headers.
/// @group spreadsheet
$kendo-spreadsheet-table-header-bg: $kendo-spreadsheet-header-bg !default;
/// The text color of the Spreadsheet table headers.
/// @group spreadsheet
$kendo-spreadsheet-table-header-text: $kendo-spreadsheet-header-text !default;
/// The border color of the Spreadsheet table headers.
/// @group spreadsheet
$kendo-spreadsheet-table-header-border: $kendo-spreadsheet-header-border !default;
/// The gradient of the Spreadsheet table headers.
/// @group spreadsheet
$kendo-spreadsheet-table-header-gradient: $kendo-spreadsheet-header-gradient !default;

/// The border width of the Spreadsheet action bar.
/// @group spreadsheet
$kendo-spreadsheet-action-bar-border-width: 1px !default;
/// The horizontal padding of the Spreadsheet action bar.
/// @group spreadsheet
$kendo-spreadsheet-action-bar-padding-y: k-map-get( $kendo-spacing, 1 ) !default;
/// The vertical padding of the Spreadsheet action bar.
/// @group spreadsheet
$kendo-spreadsheet-action-bar-padding-x: k-map-get( $kendo-spacing, 2 ) !default;
/// The font size of the Spreadsheet action bar.
/// @group spreadsheet
$kendo-spreadsheet-action-bar-font-size: $kendo-spreadsheet-font-size !default;
/// The font family of the Spreadsheet action bar.
/// @group spreadsheet
$kendo-spreadsheet-action-bar-font-family: Arial, Verdana, sans-serif !default;
/// The spacings of the Spreadsheet action bar.
/// @group spreadsheet
$kendo-spreadsheet-action-bar-spacing: k-map-get( $kendo-spacing, 2 ) !default;

/// The gap of the Spreadsheet formula bar.
/// @group spreadsheet
$kendo-spreadsheet-formula-bar-gap: k-map-get( $kendo-spacing, 2 ) !default;

/// The horizontal padding of the Spreadsheet formula input.
/// @group spreadsheet
$kendo-spreadsheet-formula-input-padding-x: $kendo-input-padding-x !default;
/// The vertical padding of the Spreadsheet formula input.
/// @group spreadsheet
$kendo-spreadsheet-formula-input-padding-y: $kendo-input-padding-y !default;
/// The line height of the Spreadsheet formula input.
/// @group spreadsheet
$kendo-spreadsheet-formula-input-line-height: $kendo-input-line-height !default;

/// The font family of the Spreadsheet view.
/// @group spreadsheet
$kendo-spreadsheet-view-font-family: Arial, Verdana, sans-serif !default;
/// The font size of the Spreadsheet view.
/// @group spreadsheet
$kendo-spreadsheet-view-font-size: $kendo-spreadsheet-font-size !default;

/// The background color of the Spreadsheet selection.
/// @group spreadsheet
$kendo-spreadsheet-selection-bg: if($kendo-enable-color-system, color-mix(in srgb, k-color( primary ) 25%, transparent), rgba($kendo-selected-bg, .25)) !default;
/// The text color of the Spreadsheet selection.
/// @group spreadsheet
$kendo-spreadsheet-selection-text: null !default;
/// The border color of the Spreadsheet selection.
/// @group spreadsheet
$kendo-spreadsheet-selection-border: $kendo-selected-bg !default;
/// The shadow of the Spreadsheet selection.
/// @group spreadsheet
$kendo-spreadsheet-selection-shadow: inset 0 0 0 1px $kendo-selected-bg !default;

/// The background color of the Spreadsheet single selection.
/// @group spreadsheet
$kendo-spreadsheet-single-selection-bg: $kendo-selected-bg !default;
/// The text color of the Spreadsheet single selection.
/// @group spreadsheet
$kendo-spreadsheet-single-selection-text: null !default;
/// The border color of the Spreadsheet single selection.
/// @group spreadsheet
$kendo-spreadsheet-single-selection-border: $kendo-spreadsheet-bg !default;

/// The background color of the Spreadsheet partial selection.
/// @group spreadsheet
$kendo-spreadsheet-partial-selection-bg: if($kendo-enable-color-system, color-mix(in srgb, k-color( primary ) 25%, transparent), rgba($kendo-selected-bg, .25)) !default;

/// The background color of the Spreadsheet active cell.
/// @group spreadsheet
$kendo-spreadsheet-active-cell-bg: $kendo-spreadsheet-bg !default;
/// The shadow of the Spreadsheet active cell.
/// @group spreadsheet
$kendo-spreadsheet-active-cell-shadow: inset 0 0 0 1px $kendo-selected-bg !default;

/// The background color of the Spreadsheet auto fill.
/// @group spreadsheet
$kendo-spreadsheet-auto-fill-bg: if($kendo-enable-color-system, color-mix(in srgb, k-color( primary ) 25%, transparent), rgba($kendo-selected-bg, .25)) !default;
/// The text color of the Spreadsheet auto fill.
/// @group spreadsheet
$kendo-spreadsheet-auto-fill-text: null !default;
/// The border color of the Spreadsheet auto fill.
/// @group spreadsheet
$kendo-spreadsheet-auto-fill-border: $kendo-selected-bg !default;
/// The shadow of the Spreadsheet auto fill.
/// @group spreadsheet
$kendo-spreadsheet-auto-fill-shadow: inset 0 0 0 1px $kendo-selected-bg !default;
/// The background color of the Spreadsheet auto fill punch.
/// @group spreadsheet
$kendo-spreadsheet-auto-fill-punch-bg: rgba( $kendo-spreadsheet-bg, .5 ) !default;

/// The vertical padding of the Spreadsheet cell.
/// @group spreadsheet
$kendo-spreadsheet-cell-padding-y: k-map-get( $kendo-spacing, 1 ) !default;
/// The horizontal padding of the Spreadsheet cell.
/// @group spreadsheet
$kendo-spreadsheet-cell-padding-x: $kendo-spreadsheet-cell-padding-y !default;

/// The line height of the Spreadsheet cell editor.
/// @group spreadsheet
$kendo-spreadsheet-cell-editor-line-height: 20px !default;
/// The horizontal padding of the Spreadsheet cell editor.
/// @group spreadsheet
$kendo-spreadsheet-cell-editor-padding-x: k-map-get( $kendo-spacing, 1 ) !default;
/// The vertical padding of the Spreadsheet cell editor.
/// @group spreadsheet
$kendo-spreadsheet-cell-editor-padding-y: 0px !default;
/// The background color of the Spreadsheet cell editor.
/// @group spreadsheet
$kendo-spreadsheet-cell-editor-bg: $kendo-spreadsheet-bg !default;
/// The text color of the Spreadsheet cell editor.
/// @group spreadsheet
$kendo-spreadsheet-cell-editor-text: $kendo-spreadsheet-text !default;
/// The border color of the Spreadsheet cell editor.
/// @group spreadsheet
$kendo-spreadsheet-cell-editor-border: null !default;

/// The background color of the Spreadsheet resize handle.
/// @group spreadsheet
$kendo-spreadsheet-resize-handle-bg: $kendo-color-primary !default;

/// The border color of the Spreadsheet cell comment indicator.
/// @group spreadsheet
$kendo-spreadsheet-cell-comment-border: $kendo-color-primary !default;

/// The border color of the Spreadsheet cell dirty indicator.
/// @group spreadsheet
$kendo-spreadsheet-cell-dirty-border: $kendo-color-error !default;

/// The horizontal padding of the Spreadsheet sheets bar.
/// @group spreadsheet
$kendo-spreadsheet-sheets-bar-padding-x: k-map-get( $kendo-spacing, 2 ) !default;
/// The vertical padding of the Spreadsheet sheets bar.
/// @group spreadsheet
$kendo-spreadsheet-sheets-bar-padding-y: k-map-get( $kendo-spacing, 2 ) !default;
/// The border width of the Spreadsheet sheets bar.
/// @group spreadsheet
$kendo-spreadsheet-sheets-bar-border-width: 1px !default;

/// The border style of the Spreadsheet image Dialog.
/// @group spreadsheet
$kendo-spreadsheet-insert-image-dialog-border-style: dashed !default;
/// The border width of the Spreadsheet image Dialog.
/// @group spreadsheet
$kendo-spreadsheet-insert-image-dialog-border-width: 2px !default;
/// The bottom margin of the Spreadsheet image Dialog.
/// @group spreadsheet
$kendo-spreadsheet-insert-image-dialog-text-margin-bottom: 30px !default;
/// The width of the Spreadsheet image Dialog preview.
/// @group spreadsheet
$kendo-spreadsheet-insert-image-dialog-preview-width: 355px !default;
/// The height of the Spreadsheet image Dialog preview.
/// @group spreadsheet
$kendo-spreadsheet-insert-image-dialog-preview-height: 230px !default;
/// The image of the Spreadsheet image Dialog preview.
/// @group spreadsheet
$kendo-spreadsheet-insert-image-dialog-preview-img: "image-default.png" !default;
/// The border of the Spreadsheet image Dialog preview.
/// @group spreadsheet
$kendo-spreadsheet-insert-image-dialog-preview-border: $kendo-component-border !default;
/// The border radius of the Spreadsheet image Dialog preview.
/// @group spreadsheet
$kendo-spreadsheet-insert-image-dialog-preview-border-radius: $kendo-border-radius-md !default;
/// The shadow of the Spreadsheet image Dialog preview.
/// @group spreadsheet
$kendo-spreadsheet-insert-image-dialog-preview-overlay-shadow: inset 0 0 0 2000px rgba(0, 0, 0, .5) !default;
/// The border radius of the Spreadsheet image Dialog preview overlay.
/// @group spreadsheet
$kendo-spreadsheet-insert-image-dialog-preview-overlay-border-radius: $kendo-border-radius-md !default;
/// The text color of the hovered Spreadsheet image Dialog preview.
/// @group spreadsheet
$kendo-spreadsheet-insert-image-dialog-overlay-hover-text: $kendo-component-bg !default;

/// The width of the Spreadsheet drawing handle.
/// @group spreadsheet
$kendo-spreadsheet-drawing-handle-width: 6px !default;
/// The height of the Spreadsheet drawing handle.
/// @group spreadsheet
$kendo-spreadsheet-drawing-handle-height: 6px !default;
/// The border style of the Spreadsheet drawing handle.
/// @group spreadsheet
$kendo-spreadsheet-drawing-handle-border-style: solid !default;
/// The border width of the Spreadsheet drawing handle.
/// @group spreadsheet
$kendo-spreadsheet-drawing-handle-border-width: 1px !default;
/// The border color of the Spreadsheet drawing handle.
/// @group spreadsheet
$kendo-spreadsheet-drawing-handle-outline-color: $kendo-color-primary !default;
/// The outline color of the Spreadsheet drawing handle.
/// @group spreadsheet
$kendo-spreadsheet-drawing-handle-border-color: $kendo-selected-bg !default;
/// The border color of the Spreadsheet drawing handle.
/// @group spreadsheet
$kendo-spreadsheet-drawing-handle-bg: $kendo-color-primary !default;
/// The border radius of the Spreadsheet drawing handle.
/// @group spreadsheet
$kendo-spreadsheet-drawing-handle-border-radius: $kendo-border-radius-lg !default;
/// The outline style of the Spreadsheet drawing.
/// @group spreadsheet
$kendo-spreadsheet-drawing-outline-style: solid !default;
/// The outline width of the Spreadsheet drawing.
/// @group spreadsheet
$kendo-spreadsheet-drawing-outline-width: 2px !default;
/// The background color of the Spreadsheet drawing anchor.
/// @group spreadsheet
$kendo-spreadsheet-drawing-anchor-bg: if($kendo-enable-color-system, color-mix(in srgb, k-color( primary ) 25%, transparent), rgba($kendo-selected-bg, .25)) !default;

/// The vertical spacing of the Spreadsheet DropZone.
/// @group spreadsheet
$kendo-spreadsheet-dropzone-spacing-y: k-map-get( $kendo-spacing, 4 ) !default;

