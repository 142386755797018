// Typography


// Headings
$kendo-h1-font-size: ($kendo-font-size-md * 3) !default;
$kendo-h2-font-size: 34px !default;
$kendo-h3-font-size: ($kendo-font-size-md * 2) !default;
$kendo-h4-font-size: 22px !default;
$kendo-h5-font-size: $kendo-font-size-lg !default;
$kendo-h6-font-size: $kendo-font-size-md !default;

$kendo-h1-font-family: $kendo-font-family !default;
$kendo-h2-font-family: $kendo-font-family !default;
$kendo-h3-font-family: $kendo-font-family !default;
$kendo-h4-font-family: $kendo-font-family !default;
$kendo-h5-font-family: $kendo-font-family !default;
$kendo-h6-font-family: $kendo-font-family !default;

$kendo-h1-line-height: 54px !default;
$kendo-h2-line-height: 46px !default;
$kendo-h3-line-height: 42px !default;
$kendo-h4-line-height: 32px !default;
$kendo-h5-line-height: 26px !default;
$kendo-h6-line-height: 20px !default;

$kendo-h1-font-weight: $kendo-font-weight-normal !default;
$kendo-h2-font-weight: $kendo-font-weight-normal !default;
$kendo-h3-font-weight: $kendo-font-weight-normal !default;
$kendo-h4-font-weight: $kendo-font-weight-bold !default;
$kendo-h5-font-weight: $kendo-font-weight-bold !default;
$kendo-h6-font-weight: $kendo-font-weight-bold !default;

$kendo-h1-letter-spacing: null !default;
$kendo-h2-letter-spacing: null !default;
$kendo-h3-letter-spacing: null !default;
$kendo-h4-letter-spacing: null !default;
$kendo-h5-letter-spacing: null !default;
$kendo-h6-letter-spacing: null !default;

$kendo-h1-margin: 0 0 $kendo-font-size-md !default;
$kendo-h2-margin: 0 0 $kendo-font-size-md !default;
$kendo-h3-margin: 0 0 $kendo-font-size-md !default;
$kendo-h4-margin: 0 0 $kendo-font-size-md !default;
$kendo-h5-margin: 0 0 $kendo-font-size-md !default;
$kendo-h6-margin: 0 0 $kendo-font-size-md !default;


// Paragraph
$kendo-paragraph-margin: 0 0 $kendo-font-size-md !default;
$kendo-paragraph-font-size: $kendo-font-size-md !default;
$kendo-paragraph-font-family: $kendo-font-family !default;
$kendo-paragraph-line-height: 20px !default;
$kendo-paragraph-font-weight: $kendo-font-weight-normal !default;
$kendo-paragraph-letter-spacing: null !default;


// Code
$kendo-code-font-size: $kendo-font-size-md !default;
$kendo-code-font-family: $kendo-font-family-monospace !default;
$kendo-code-line-height: 20px !default;
$kendo-code-font-weight: $kendo-font-weight-normal !default;
$kendo-code-letter-spacing: null !default;

$kendo-code-padding-x: 4px !default;
$kendo-code-padding-y: 0px !default;
$kendo-pre-padding-x: 24px !default;
$kendo-pre-padding-y: 16px !default;
$kendo-code-border-width: 1px !default;

$kendo-code-bg: $kendo-base-bg !default;
$kendo-code-text: $kendo-component-text !default;
$kendo-code-border: $kendo-component-border !default;


// Display
$kendo-display1-font-size: ($kendo-font-size-md * 6) !default;
$kendo-display2-font-size: ($kendo-font-size-md * 5) !default;
$kendo-display3-font-size: ($kendo-font-size-md * 4) !default;
$kendo-display4-font-size: ($kendo-font-size-md * 3) !default;

$kendo-display1-font-family: $kendo-font-family !default;
$kendo-display2-font-family: $kendo-font-family !default;
$kendo-display3-font-family: $kendo-font-family !default;
$kendo-display4-font-family: $kendo-font-family !default;

$kendo-display1-line-height: 1.2 !default;
$kendo-display2-line-height: 1.2 !default;
$kendo-display3-line-height: 1.2 !default;
$kendo-display4-line-height: 1.2 !default;

$kendo-display1-font-weight: $kendo-font-weight-light !default;
$kendo-display2-font-weight: $kendo-font-weight-light !default;
$kendo-display3-font-weight: $kendo-font-weight-light !default;
$kendo-display4-font-weight: $kendo-font-weight-light !default;

$kendo-display1-letter-spacing: null !default;
$kendo-display2-letter-spacing: null !default;
$kendo-display3-letter-spacing: null !default;
$kendo-display4-letter-spacing: null !default;
