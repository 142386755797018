@import "../button/_variables.scss";


// Chart

/// The first base series color and its light and dark shades.
/// @group charts
$kendo-series-a: if($kendo-enable-color-system, k-color( series-a ), #ff6358) !default;
$kendo-series-a-dark: if($kendo-enable-color-system, k-color( series-a-bold ), k-color-mix(black, $kendo-series-a, 25%)) !default;
$kendo-series-a-darker: if($kendo-enable-color-system, k-color( series-a-bolder ), k-color-mix(black, $kendo-series-a, 50%)) !default;
$kendo-series-a-light: if($kendo-enable-color-system, k-color( series-a-subtle ), k-color-mix(white, $kendo-series-a, 25%)) !default;
$kendo-series-a-lighter: if($kendo-enable-color-system, k-color( series-a-subtler ), k-color-mix(white, $kendo-series-a, 50%)) !default;

/// The second base series color and its light and dark shades.
/// @group charts
$kendo-series-b: if($kendo-enable-color-system, k-color( series-b ), #ffe162) !default;
$kendo-series-b-dark: if($kendo-enable-color-system, k-color( series-b-bold ), k-color-mix(black, $kendo-series-b, 25%)) !default;
$kendo-series-b-darker: if($kendo-enable-color-system, k-color( series-b-bolder ), k-color-mix(black, $kendo-series-b, 50%)) !default;
$kendo-series-b-light: if($kendo-enable-color-system, k-color( series-b-subtle ), k-color-mix(white, $kendo-series-b, 25%)) !default;
$kendo-series-b-lighter: if($kendo-enable-color-system, k-color( series-b-subtler ), k-color-mix(white, $kendo-series-b, 50%)) !default;

/// The third base series color and its light and dark shades.
/// @group charts
$kendo-series-c: if($kendo-enable-color-system, k-color( series-c ), #4cd180) !default;
$kendo-series-c-dark: if($kendo-enable-color-system, k-color( series-c-bold ), k-color-mix(black, $kendo-series-c, 25%)) !default;
$kendo-series-c-darker: if($kendo-enable-color-system, k-color( series-c-bolder ), k-color-mix(black, $kendo-series-c, 50%)) !default;
$kendo-series-c-light: if($kendo-enable-color-system, k-color( series-c-subtle ), k-color-mix(white, $kendo-series-c, 25%)) !default;
$kendo-series-c-lighter: if($kendo-enable-color-system, k-color( series-c-subtler ), k-color-mix(white, $kendo-series-c, 50%)) !default;

/// The fourth base series color and its light and dark shades.
/// @group charts
$kendo-series-d: if($kendo-enable-color-system, k-color( series-d ), #4b5ffa) !default;
$kendo-series-d-dark: if($kendo-enable-color-system, k-color( series-d-bold ), k-color-mix(black, $kendo-series-d, 25%)) !default;
$kendo-series-d-darker: if($kendo-enable-color-system, k-color( series-d-bolder ), k-color-mix(black, $kendo-series-d, 50%)) !default;
$kendo-series-d-light: if($kendo-enable-color-system, k-color( series-d-subtle ), k-color-mix(white, $kendo-series-d, 25%)) !default;
$kendo-series-d-lighter: if($kendo-enable-color-system, k-color( series-d-subtler ), k-color-mix(white, $kendo-series-d, 50%)) !default;

/// The fifth base series color and its light and dark shades.
/// @group charts
$kendo-series-e: if($kendo-enable-color-system, k-color( series-e ), #ac58ff) !default;
$kendo-series-e-dark: if($kendo-enable-color-system, k-color( series-e-bold ), k-color-mix(black, $kendo-series-e, 25%)) !default;
$kendo-series-e-darker: if($kendo-enable-color-system, k-color( series-e-bolder ), k-color-mix(black, $kendo-series-e, 50%)) !default;
$kendo-series-e-light: if($kendo-enable-color-system, k-color( series-e-subtle ), k-color-mix(white, $kendo-series-e, 25%)) !default;
$kendo-series-e-lighter: if($kendo-enable-color-system, k-color( series-e-subtler ), k-color-mix(white, $kendo-series-e, 50%)) !default;

/// The sixth base series color and its light and dark shades.
/// @group charts
$kendo-series-f: if($kendo-enable-color-system, k-color( series-f ), #ff5892) !default;
$kendo-series-f-dark: if($kendo-enable-color-system, k-color( series-f-bold ), k-color-mix(black, $kendo-series-f, 25%)) !default;
$kendo-series-f-darker: if($kendo-enable-color-system, k-color( series-f-bolder ), k-color-mix(black, $kendo-series-f, 50%)) !default;
$kendo-series-f-light: if($kendo-enable-color-system, k-color( series-f-subtle ), k-color-mix(white, $kendo-series-f, 25%)) !default;
$kendo-series-f-lighter: if($kendo-enable-color-system, k-color( series-f-subtler ), k-color-mix(white, $kendo-series-f, 50%)) !default;

/// The series colors in order:
/// base, light, dark, lighter, darker
/// @group charts
$kendo-series-1: $kendo-series-a !default;
$kendo-series-2: $kendo-series-b !default;
$kendo-series-3: $kendo-series-c !default;
$kendo-series-4: $kendo-series-d !default;
$kendo-series-5: $kendo-series-e !default;
$kendo-series-6: $kendo-series-f !default;
$kendo-series-7: $kendo-series-a-light !default;
$kendo-series-8: $kendo-series-b-light !default;
$kendo-series-9: $kendo-series-c-light !default;
$kendo-series-10: $kendo-series-d-light !default;
$kendo-series-11: $kendo-series-e-light !default;
$kendo-series-12: $kendo-series-f-light !default;
$kendo-series-13: $kendo-series-a-dark !default;
$kendo-series-14: $kendo-series-b-dark !default;
$kendo-series-15: $kendo-series-c-dark !default;
$kendo-series-16: $kendo-series-d-dark !default;
$kendo-series-17: $kendo-series-e-dark !default;
$kendo-series-18: $kendo-series-f-dark !default;
$kendo-series-19: $kendo-series-a-lighter !default;
$kendo-series-20: $kendo-series-b-lighter !default;
$kendo-series-21: $kendo-series-c-lighter !default;
$kendo-series-22: $kendo-series-d-lighter !default;
$kendo-series-23: $kendo-series-e-lighter !default;
$kendo-series-24: $kendo-series-f-lighter !default;
$kendo-series-25: $kendo-series-a-darker !default;
$kendo-series-26: $kendo-series-b-darker !default;
$kendo-series-27: $kendo-series-c-darker !default;
$kendo-series-28: $kendo-series-d-darker !default;
$kendo-series-29: $kendo-series-e-darker !default;
$kendo-series-30: $kendo-series-f-darker !default;

$kendo-chart-border-width: 0px !default;
$kendo-chart-font-family: $kendo-font-family !default;
$kendo-chart-font-size: $kendo-font-size-md !default;
$kendo-chart-line-height: $kendo-line-height-md !default;
$kendo-chart-sm-font-size: 11px !default;
$kendo-chart-md-font-size: 12px !default;
$kendo-chart-lg-font-size: 16px !default;
$kendo-chart-tooltip-font-size: ($kendo-font-size-md * .929) !default;
$kendo-chart-label-font-size: .857em !default;
$kendo-chart-title-font-size: 1.143em !default;
$kendo-chart-pane-title-font-size: $kendo-chart-label-font-size !default;
$kendo-chart-pane-title-font-weight: $kendo-font-weight-normal !default;

/// The color of the Chart grid lines (major).
/// @group charts
$kendo-chart-major-lines: if($kendo-enable-color-system, color-mix(in srgb, k-color( on-app-surface ) 8%, transparent), rgba( if( $kendo-is-dark-theme, $kendo-color-white, $kendo-color-black ), .08 )) !default;

/// The color of the Chart grid lines (minor).
/// @group charts
$kendo-chart-minor-lines: if($kendo-enable-color-system, color-mix(in srgb, k-color( on-app-surface ) 4%, transparent), rgba( if( $kendo-is-dark-theme, $kendo-color-white, $kendo-color-black ), .04 )) !default;

$kendo-chart-inactive: if($kendo-enable-color-system, color-mix(in srgb, k-color( on-app-surface ) 50%, transparent), rgba( $kendo-body-text, .5 )) !default;
$kendo-chart-area-opacity: .6 !default;
$kendo-chart-area-inactive-opacity: .1 !default;
$kendo-chart-line-inactive-opacity: .3 !default;

$kendo-chart-tooltip-transition: left 300ms cubic-bezier(0, .33, .19, 1), top 300ms cubic-bezier(0, .33, .19, 1) !default;

$kendo-chart-bg: $kendo-component-bg !default;
$kendo-chart-text: $kendo-component-text !default;
$kendo-chart-border: $kendo-component-border !default;

$kendo-chart-crosshair-background: if($kendo-enable-color-system, color-mix(in srgb, k-color( on-app-surface ) 50%, transparent), rgba( if( $kendo-is-dark-theme, $kendo-color-white, $kendo-color-black ), .5 )) !default;
$kendo-chart-crosshair-shared-tooltip-color: $kendo-chart-text !default;
$kendo-chart-crosshair-shared-tooltip-background: if($kendo-enable-color-system, k-color( base ), k-try-shade( $kendo-chart-bg, 1 )) !default;
$kendo-chart-crosshair-shared-tooltip-border: if($kendo-enable-color-system, color-mix(in srgb, k-color( on-app-surface ) 8%, transparent), rgba( if( $kendo-is-dark-theme, $kendo-color-white, $kendo-color-black ), .08 )) !default;

$kendo-chart-notes-background: if($kendo-enable-color-system, color-mix(in srgb, k-color( on-app-surface ) 50%, transparent), rgba( if( $kendo-is-dark-theme, $kendo-color-white, $kendo-color-black ), .5 )) !default;
$kendo-chart-notes-border: if($kendo-enable-color-system, color-mix(in srgb, k-color( on-app-surface ) 50%, transparent), rgba( if( $kendo-is-dark-theme, $kendo-color-white, $kendo-color-black ), .5 )) !default;
$kendo-chart-notes-lines: if($kendo-enable-color-system, color-mix(in srgb, k-color( on-app-surface ) 50%, transparent), rgba( if( $kendo-is-dark-theme, $kendo-color-white, $kendo-color-black ), .5 )) !default;

$kendo-chart-error-bars-background: if($kendo-enable-color-system, color-mix(in srgb, k-color( on-app-surface ) 50%, transparent), rgba( if( $kendo-is-dark-theme, $kendo-color-white, $kendo-color-black ), .5 )) !default;

$kendo-chart-selection-handle-size: 22px !default;
$kendo-chart-selection-border-color: if($kendo-enable-color-system, color-mix(in srgb, k-color( on-app-surface ) 8%, transparent), rgba( if( $kendo-is-dark-theme, $kendo-color-white, $kendo-color-black ), .08 )) !default;
$kendo-chart-selection-shadow: inset 0 1px 7px if($kendo-enable-color-system, color-mix(in srgb, k-color( on-app-surface ) 15%, transparent), rgba( if( $kendo-is-dark-theme, $kendo-color-white, $kendo-color-black ), .15 )) !default;


// TreeMap
$kendo-treemap-font-family: $kendo-font-family !default;
$kendo-treemap-font-size: $kendo-font-size-md !default;
$kendo-treemap-line-height: $kendo-line-height-md !default;
