// ListView

/// The horizontal padding of the ListView.
/// @group listview
$kendo-listview-padding-x: k-map-get( $kendo-spacing, 1 ) !default;
/// The vertical padding of the ListView.
/// @group listview
$kendo-listview-padding-y: k-map-get( $kendo-spacing, 1 ) !default;
/// The width of the border around bordered ListView.
/// @group listview
$kendo-listview-border-width: 1px !default;
/// The font family of the ListView.
/// @group listview
$kendo-listview-font-family: $kendo-font-family !default;
/// The font size of the ListView.
/// @group listview
$kendo-listview-font-size: $kendo-font-size-md !default;
/// The line height of the ListView.
/// @group listview
$kendo-listview-line-height: $kendo-line-height-md !default;

/// The text color of the ListView.
/// @group listview
$kendo-listview-text: $kendo-component-text !default;
/// The background color of the ListView.
/// @group listview
$kendo-listview-bg: $kendo-component-bg !default;
/// The border color of the ListView.
/// @group listview
$kendo-listview-border: $kendo-component-border !default;

/// The horizontal padding of the ListView items.
/// @group listview
$kendo-listview-item-padding-x: k-map-get( $kendo-spacing, 1 ) !default;
/// The vertical padding of the ListView items.
/// @group listview
$kendo-listview-item-padding-y: k-map-get( $kendo-spacing, 1 ) !default;

/// The text color of the selected ListView items.
/// @group listview
$kendo-listview-item-selected-text: null !default;
/// The background color of the selected ListView items.
/// @group listview
$kendo-listview-item-selected-bg: if($kendo-enable-color-system, color-mix(in srgb, k-color( primary ) 25%, transparent), rgba($kendo-selected-bg, .25)) !default;
/// The border color of the selected ListView items.
/// @group listview
$kendo-listview-item-selected-border: null !default;

/// The text color of the focused ListView items.
/// @group listview
$kendo-listview-item-focus-text: null !default;
/// The background color of the focused ListView items.
/// @group listview
$kendo-listview-item-focus-bg: null !default;
/// The border color of the focused ListView items.
/// @group listview
$kendo-listview-item-focus-border: null !default;
/// The box shadow of the focused ListView items.
/// @group listview
$kendo-listview-item-focus-shadow: inset 0 0 0 2px rgba(0, 0, 0, .13) !default;
