// TileLayout

/// The width of the border around the TileLayout.
/// @group tilelayout
$kendo-tile-layout-border-width: 0px !default;
/// The background color of the TileLayout.
/// @group tilelayout
$kendo-tile-layout-bg: $kendo-base-bg !default;

/// The horizontal padding of the TileLayout.
/// @group tilelayout
$kendo-tile-layout-padding-x: k-map-get( $kendo-spacing, 4 ) !default;

/// The vertical padding of the TileLayout
/// @group tilelayout
$kendo-tile-layout-padding-y: $kendo-tile-layout-padding-x !default;

/// The width of the border around the TileLayout card.
/// @group tilelayout
$kendo-tile-layout-card-border-width: $kendo-card-border-width !default;
/// The focus box shadow of the TileLayout card.
/// @group tilelayout
$kendo-tile-layout-card-focus-shadow: $kendo-card-focus-shadow !default;

/// The width of the border around the TileLayout hint.
/// @group tilelayout
$kendo-tile-layout-hint-border-width: 1px !default;
/// The radius of the border around the TileLayout hint.
/// @group tilelayout
$kendo-tile-layout-hint-border-radius: $kendo-border-radius-lg !default;
/// The color of the border around the TileLayout hint.
/// @group tilelayout
$kendo-tile-layout-hint-border: $kendo-component-border !default;
/// The background color of the TileLayout hint.
/// @group tilelayout
$kendo-tile-layout-hint-bg: rgba(255, 255, 255, .2) !default;



